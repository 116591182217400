<template>
  <div class="row">
    <div class="col-xl-5">
      <div id="panel-1" class="panel" v-bind:class="{ 'panel-collapsed panel-fullscreen' : isFullTree }">
        <div class="panel-hdr">
          <h2>
            Phân cấp nhân sự
          </h2>
          <div class="panel-toolbar">
            <button class="btn btn-panel bg-transparent fs-xl w-auto h-auto rounded-0"
                    :class="{'mr-3': isFullTree}"
                    data-action="panel-fullscreen"
                    data-toggle="tooltip"
                    data-offset="0,10"
                    data-original-title="Fullscreen"
                    @click="isFullTree = !isFullTree">
              <i class="fal" :class="{'fa-times': isFullTree, 'fa-expand': !isFullTree}"></i>
            </button>
          </div>
        </div>
        <div class="panel-container show">
          <div class="panel-content">
            <div class="panel-tag">
              <div class="form-row">
                <div class="col-md-4 mb-1 mt-1" v-if="isGDKV">
                  <el-select clearable v-model="tree.branch_id" filterable class="w-100"
                             placeholder="Chọn văn phòng để xem" @change="handleChangeBranch">
                    <el-option
                        v-for="item in branches"
                        :key="item.id"
                        :label="item.name_alias ? item.name_alias : item.name"
                        placeholder="Chọn cơ sở"
                        :value="item.id">
                      <span style="float: left">{{ item.name_alias ? item.name_alias : item.name }}</span>
                    </el-option>
                  </el-select>
                </div>
                <div class="col-md-4 mb-1 mt-1">
                  <el-date-picker class="w-100"
                                  v-model="tree.selectDate"
                                  type="month"
                                  format="yyyy-MM"
                                  value-format="yyyy-MM"
                                  :picker-options="pickerOptions"
                                  placeholder="Chọn tháng">
                  </el-date-picker>
                </div>
                <div class="col-md-4 mb-1 mt-1">
                  <el-input placeholder="Tìm theo tên, mã nhân sự..." clearable v-model="tree.keyword">
                    <i slot="prefix" class="el-input__icon el-icon-search"></i></el-input>
                </div>
                <div class="col-md-4 mt-2">
                  <el-button type="primary" :disabled="loading" :loading="loading" @click="handleChangeBranch">Tìm kiếm
                  </el-button>
                </div>
              </div>

            </div>
            <div>
              <el-tree
                  :data="data"
                  node-key="id"
                  v-loading="loading"
                  v-if="data"
                  :props="defaultProps"
                  ref="tree"
                  @node-click="handleNodeClick"
                  :filter-node-method="filterNode"
                  highlight-current
                  :expand-on-click-node="true"
              >
                <span class="custom-tree-node" slot-scope="{ node }">
                                        <img
                                            :src="getImageHierarchy(node?.data?.account_type_id ? node?.data?.account_type_id : node?.data?.profile?.account_type?.id)"
                                            alt="">
                                        <span>{{
                                            node?.data?.name
                                          }} - {{ node?.data?.id ? node?.data?.id + 100000 : "Không có mã nhân sự" }}</span> <span> {{
                    workplace(node.data) ? "  - " + workplace(node.data) : ''
                  }}</span>
                                  </span>
              </el-tree>
              <el-empty v-else description="Chưa chọn văn phòng"></el-empty>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="col-xl-7">
      <div id="panel-2" class="panel" v-bind:class="{ 'panel-collapsed panel-fullscreen' : isFullDetail }">
        <div class="panel-hdr">
          <h2 v-if="!userDetail">
          </h2>
          <h2 v-else>
            Thông tin cơ bản
          </h2>
          <div class="panel-toolbar">
            <button class="btn btn-panel bg-transparent fs-xl w-auto h-auto rounded-0"
                    :class="{'mr-3': isFullDetail}" data-action="panel-fullscreen" data-toggle="tooltip"
                    data-offset="0,10" data-original-title="Fullscreen"
                    @click="isFullDetail = !isFullDetail">
              <i class="fal" :class="{'fa-times': isFullDetail, 'fa-expand': !isFullDetail}"></i>
            </button>
            <button class="btn btn-panel bg-transparent fs-xl w-auto h-auto rounded-0" v-if="!isFullDetail"
                    :class="{'mr-3': isFullDetail}" data-action="panel-fullscreen" data-toggle="tooltip"
                    data-offset="0,10" data-original-title="Fullscreen"
                    @click="userDetail = null">
              <i class="fal fa-window-alt"></i>
            </button>
          </div>
        </div>
        <div class="panel-container show">
          <div class="panel-content">
            <el-row shadow="never" v-loading="loading">
              <el-empty v-if="!userDetail"
                        description="Chọn nhân sự bên phân cấp nhân sự để xem chi tiết"></el-empty>
              <div v-else>
                <div class="form-row">
                  <div class="col-md-3 mb-3">
                    <label class="form-label" for="code">Mã nhân viên</label>
                    <input disabled type="text" class="form-control" id="code"
                           placeholder="Mã nhân viên"
                           autocomplete="false"
                           :value="userDetail.user_id ? userDetail.user_id + 100000 : userDetail.id + 100000">
                  </div>
                  <div class="col-md-3 mb-3">
                    <label class="form-label" for="name_code">Họ và tên</label>
                    <input disabled type="text" id="name_code" class="form-control"
                           placeholder="Họ và tên"
                           autocomplete="false" :value="userDetail.name"/>
                  </div>
                  <div class="col-md-3 mb-3">
                    <label class="form-label" for="position">Vị trí</label>
                    <input disabled type="text" id="position" class="form-control"
                           placeholder="Vị trí"
                           autocomplete="false"
                           :value="userDetail?.profile?.account_type?.name">
                  </div>
                  <div class="col-md-3 mb-3" v-if="is_show_business_level">
                    <label class="form-label" for="position">Cấp bậc</label>
                    <input disabled type="text" id="position" class="form-control"
                           placeholder="Cấp bậc"
                           autocomplete="false"
                           :value="userDetail.profile.business_level ? userDetail.profile.business_level.name : 'Chưa có cấp bậc'">
                  </div>
                </div>
                <div class="form-row mt-3">
                  <div class="col-md-3 mb-3">
                    <label class="form-label">Trạng thái</label>
                    <div class="mt-2">
                      <el-link class="defaultCursor"
                               :type="!userDetail.lay_off_time ? 'success': 'default'"
                               href="javascript:void(0))" :underline="false"
                               icon="el-icon-success">{{
                          !userDetail.lay_off_time ? 'Đang làm việc' : 'Đã nghỉ'
                        }}
                      </el-link>
                    </div>
                  </div>
                  <div class="col-md-3 mb-3 col-sm-6 col-xs-6">
                    <label class="form-label">Doanh số key</label>
                    <div class="mt-2">
                      <el-link type="danger" v-loading="loadingRevenue" class="defaultCursor"
                               :underline="false" href="javascript:void(0))">{{
                          revenue.teamWork +
                          revenue.personal > 0 ? formatVND((revenue.teamWork +
                              revenue.personal))  : 'Chưa có doanh số'
                        }}
                      </el-link>
                    </div>
                  </div>
                  <div class="col-md-3 mb-3 col-sm-6 col-xs-6">
                    <label class="form-label">Doanh số cá nhân</label>
                    <div class="mt-2">
                      <el-link type="danger" v-loading="loadingRevenue" :underline="false"
                               class="defaultCursor" href="javascript:void(0))">{{
                          revenue.personal > 0 ? formatVND((revenue.personal)) : 'Chưa có doanh số'
                        }}
                      </el-link>
                    </div>
                  </div>
                  <div class="col-md-3 mb-3">
                    <label class="form-label">Khách hàng</label>
                    <div class="mt-2">
                      <el-link class="text-info" :underline="true" @click="viewDetail">Xem chi
                        tiết<i class="el-icon-view el-icon--right"></i></el-link>
                    </div>
                  </div>
                  <div class="col-md-6 mb-3" v-if="detailCustomer">
                    <div class="mt-2">
                      <el-input
                          @keyup.native="searchCustomer"
                          placeholder="Tìm kiếm theo tên, số điện thoại khách hàng"
                          v-model="customer">
                        <i slot="prefix" class="el-input__icon el-icon-search"></i>
                      </el-input>
                    </div>
                  </div>
                  <div class="col-md-12" v-if="detailCustomer">
                    <el-table
                        :data="tableData"
                        stripe
                        v-loading="loadingCustomer"
                        border
                        :default-sort="{prop: 'date', order: 'descending'}"
                        tableSize=" table-bordered table-striped table-hover"
                        class="table-hover"
                        style="width: 100%">
                      <el-table-column label="#" type="index" width="50" :index="indexMethod">
                      </el-table-column>
                      <el-table-column
                          prop="name"
                          label="Họ và tên"
                          width="180">
                      </el-table-column>
                      <el-table-column
                          prop="phone"
                          label="Điện thoại">
                      </el-table-column>
                      <el-table-column
                          prop="created_event"
                          label="T/gian tạo lịch">
                      </el-table-column>
                      <el-table-column
                          prop="ngay_hen"
                          label="Lịch hẹn">
                      </el-table-column>
                      <el-table-column label="Tư vấn viên">
                        <template slot-scope="scope">
                          <span class="text-break">{{ scope.row.tuvanvien }}</span>
                        </template>
                      </el-table-column>
                      <el-table-column label="Cơ sở hẹn">
                        <template slot-scope="scope">
                          <span class="text-break">{{ scope.row.center }}</span>
                        </template>
                      </el-table-column>
                      <el-table-column
                          prop="level"
                          width="60"
                          label="Level">
                      </el-table-column>
                      <el-table-column
                          label="Action" width="70">
                        <template slot-scope="scope">
                          <router-link href="">
                            <i class="el-icon-view"></i>
                          </router-link>
                          <el-button type="primary" size="mini"
                                     @click="$router.push({name: 'customer-edit', params: {id: scope.row.id}})">
                            <i
                                class="el-icon-view"></i>
                          </el-button>
                        </template>
                      </el-table-column>
                    </el-table>
                    <div class="edutalk-paging">
                      <div class="block">
                        <!--eslint-disable-->
                        <el-pagination
                            background
                            @current-change="handleCurrentChange"
                            :current-page.sync="paging.current_page"
                            :page-size="25"
                            layout="prev, pager, next"
                            :total="paging.total">
                        </el-pagination>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </el-row>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {SET_BREADCRUMB} from "@/core/services/store/breadcrumbs.module";
import {BRANCH_LIST, HIERARCHY} from "@/core/services/store/service.module";
import {PAYMENT_CUSTOMER, PAYMENT_REVENUE} from "@/core/services/store/payment.module";
import ACCOUNT_TYPE from "@/core/config/hierarchy";
import {
  GDKV,
  GDVP,
  HANH_CHINH_KHU_VUC,
  QLHV,
  SALE_EXPERT,
  SALE_INTERN,
  SALE_LEADER,
  SALE_MEMBER
} from "@/core/config/accountTypeOption";
import lodash from 'lodash-es';
import deepdash from 'deepdash-es';
import {mapGetters} from "vuex";
import {formatVND} from "../../../core/filters";

const _ = deepdash(lodash);

export default {
  name: "EdutalkHierarchy",
  components: {},
  data() {
    return {
      isFullTree: false,
      isFullDetail: false,
      loading: false,
      loadingRevenue: false,
      loadingCustomer: false,
      detailCustomer: false,
      tableData: [],
      SALE_LEADER,
      SALE_EXPERT,
      SALE_MEMBER,
      SALE_INTERN,
      GDVP,
      QLHV,
      HANH_CHINH_KHU_VUC,
      GDKV,
      imageHierarchy: {
        '26': 'media/hierarchy/rsm.png',
        '5': 'media/hierarchy/gdvp.png',
        '1': 'media/hierarchy/sl.png',
        '2': 'media/hierarchy/se.png',
        '3': 'media/hierarchy/s.png',
        '4': 'media/hierarchy/si.png',
        '6': 'media/hierarchy/gdkv.png',
      },
      pickerOptions: {
        disabledDate(time) {
          return time.getTime() > Date.now();
        }
      },
      branches: null,
      isGDKV: false,
      customer: '',
      paging: {
        current_page: 1,
        per_page: 50,
        total: 0,
      },
      currentPage: 1,
      tree: {
        branch_id: null,
        selectDate: null,
        keyword: '',
      },
      revenue: {
        personal: null,
        teamWork: null
      },
      account_type: ACCOUNT_TYPE,
      filterText: '',
      data: [],
      defaultProps: {
        children: 'children',
        label: 'name',
      },
      userDetail: null,
      timeout: null,
      is_show_business_level: false
    }
  },
  mounted() {
    this.setBreadcrumb();
    this.settingDefault();
  },
  watch: {
    filterText(val) {
      this.$refs.tree.filter(val);
    }
  },
  methods: {
    formatVND,
    checkShowLevelOption() {
      this.is_show_business_level = false;
      if ([SALE_MEMBER, SALE_LEADER, GDKV, GDVP].includes(this.userDetail.profile.account_type_id)) {
        this.is_show_business_level = true;
      }
    },
    settingDefault() {
      // this.tree.branch_id = this.currentUser.profile.chi_nhanh_id;
      if (this.currentUser.profile.khuvuc_id > 0 && this.currentUser.profile.account_type.id === this.GDKV || this.currentUser.profile.account_type.id === this.HANH_CHINH_KHU_VUC) {
        this.isGDKV = true;
        this.getBranches();
      } else {
        this.handleChangeBranch()
      }
    },
    setBreadcrumb() {
      this.$store.dispatch(SET_BREADCRUMB, [
        {title: "Phân cấp nhân sự", icon: 'far fa-sitemap'}
      ]);
    },
    indexMethod(index) {
      return 5 * (this.currentPage - 1) + (index + 1);
    },
    filterNode(value, data) {
      if (!value) return true;
      return data.label.indexOf(value) !== -1
    },
    handleCurrentChange(val) {
      this.currentPage = val;
      this.loadingCustomer = true;
      this.queryCustomer({page: val});
    },
    getImageHierarchy(account_type) {
      return this.imageHierarchy[account_type];
    },
    handleNodeClick(data) {
      this.userDetail = data;
      this.loadingRevenue = true;
      if (this.detailCustomer) {
        this.currentPage = 1;
        this.loadingCustomer = true;
        this.queryCustomer();
      }
      this.getRevenue();
      this.checkShowLevelOption();
    },
    getRevenue: function () {
      this.revenue.teamWork = 0;
      this.revenue.personal = 0;
      let user_id = '';
      // Nếu là log thì lấy field user_id
      if (this.userDetail.user_id) {
        user_id = this.userDetail.user_id;
      } else {
        user_id = this.userDetail.id;
      }
      let params = {
        id: user_id
      };

      this.$store.dispatch(PAYMENT_REVENUE, _.merge(params, {viewDate: this.tree.selectDate}))
          .then((response) => {
            if (response.status === 422) {
              this.loadingRevenue = false;
              this.$message({type: 'warning', message: `${response.message}`, showClose: true});
            } else {
              this.loadingRevenue = false;
              this.revenue.personal = response.data.personal.tong != null ? response.data.personal.tong : 0;
              this.revenue.teamWork = response.data.teamWork.tong != null ? (response.data.teamWork.tong - this.revenue.personal) : 0;
            }
          })
    },
    getBranches() {
      this.$store.dispatch(BRANCH_LIST)
          .then((response) => {
            if (response.status === 422) {
              this.loading = false;
              this.$message({type: 'warning', message: `${response.message}`, showClose: true});
            } else {
              this.branches = response.data;
              this.tree.branch_id = response.data.length > 0 ? response.data[0].id : 0;
              this.handleChangeBranch()
            }
          })

    },
    handleChangeBranch() {
      this.loading = true;
      this.userDetail = false;
      if (this.currentUser.profile.account_type.id == 26) {
        this.tree.branch_id = '';
      }
      this.$store.dispatch(HIERARCHY, {
        branch_id: this.tree.branch_id ?? 0,
        viewDate: this.tree.selectDate,
        keyword: this.tree.keyword
      }).then((response) => {
        this.data = response.data;
        this.loading = false;
      })
    },

    viewDetail() {
      this.detailCustomer = !this.detailCustomer
      if (this.detailCustomer) {
        this.loadingCustomer = true;
        this.queryCustomer();
      }
    },

    workplace(item) {
      let account_type_item = item?.profile?.account_type_id;
      switch (account_type_item) {
        case GDVP:
          return this.caseGdvp(item);
        case GDKV:
          return this.caseGdkv(item);
        default:
          return null;
      }
    },
    caseGdkv(item) {
      if (item?.area) {
        return item?.area?.name;
      }
      return item?.profile?.branch?.area?.name;
    },
    caseGdvp(item) {
      if (item?.branch) {
        return item?.branch?.name_alias ? item?.branch?.name_alias : item?.branch?.name;
      }
      return item?.profile?.branch?.name_alias ? item?.profile?.branch?.name_alias : item?.profile?.branch?.name;
    },
    searchCustomer: function () {
      // clear timeout variable
      clearTimeout(this.timeout);
      var self = this;
      this.currentPage = 1;
      this.timeout = setTimeout(function () {
        self.queryCustomer({search: self.customer});
      }, 1000);
    },
    queryCustomer(customProperties) {
      let params = this.mergeParams(customProperties);
      this.$store.dispatch(PAYMENT_CUSTOMER, params)
          .then((response) => {
            this.loadingCustomer = false;
            this.tableData = response.data;
            this.paging = response.pagination
          })
    },
    mergeParams(customProperties) {
      let params = {
        id: (this.userDetail.id + 100000)
      };
      if (this.customer) {
        params = _.merge(params, {search: this.customer})
      }
      params = _.merge(params, customProperties);
      return params;
    }
  },
  computed: {
    ...mapGetters(['currentUser'])
  }

}
</script>

<style scoped>

</style>
